jQuery(function ($) {
  // -------------------------------------- bouton top page
  window.addEventListener("scroll", () => {
    const position = window.pageYOffset;
    const btnTop = document.querySelector(".btn__top");

    if (position > 300) {
      btnTop.classList.add("btn__top--active");
    } else {
      btnTop.classList.remove("btn__top--active");
    }

    btnTop.addEventListener("click", () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
  });
});
