import Swiper from "swiper/bundle";

jQuery(function ($) {
  // ------------------------------------------- SWIPER
  // ------------------------------------------- SLIDE
  const swiper = new Swiper(".swiper__slide", {
    slidesPerView: 1,
    loop: true,
    // loopAdditionalSlides: 20,
    // freeMode: true,
    // spaceBetween: 5,
    speed: 700,
    // autoplay: {
    //   delay: 3500,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: ".swiper-pagination",
      //   clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  $(".swiper__slide .swiper-slide").hover(
    function () {
      swiper.autoplay.stop();
    },
    function () {
      //   swiper.autoplay.start();
    }
  );

  const swiper2 = new Swiper(".swiper__realisations", {
    slidesPerView: "auto",
    loop: true,
    autoWidth: "true",
    centeredSlides: true,
    // loopAdditionalSlides: 20,
    spaceBetween: 5,
    speed: 700,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    breakpoints: {
      640: {
        centeredSlides: false,
      },
    },
  });
});
