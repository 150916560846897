import "../sass/main.scss";
import "./navigation";
import "./home";
import "./utils";

jQuery(document).ready(function ($) {
  $(".close").click(function () {
    $(".alert").hide();
  });
});
